$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$mine-shaft-light: rgba(45, 45, 45, 1);
$mine-shaft: rgba(56, 56, 58, 1);
$mine-shaft-half: rgba(56, 56, 58, .5);
$shark: rgba(26, 26, 28, 1);
$strong-faded-white: rgba(255, 255, 255, .10);
$faded-white: rgba(255, 255, 255, 0.23);
$half-white: rgba(255, 255, 255, 0.42);
$almost-white: rgba(255, 255, 255, 0.72);
$mid-gray: rgba(110, 110, 111, 1);
$dodger-blue: rgba(62, 151, 252, 1);
$malibu: rgba(101, 179, 255, 1);
$eucalyptus: rgba(42, 159, 86, 1);
$brick-red: rgba(206, 62, 84, 1);
$scrollbar-front: rgba(17, 23, 26, 1);
$scrollbar-back: rgba(255, 255, 255, 0.5);

$fonts_path: "/assets/fonts/";
$images_path: "/assets/images/";

$roboto-regular: 400 14px Roboto, serif;
