@import "../../../../styles/variables";

.text-input {
  position: relative;
  width: 100%;
}

.text-input__element {
  width: 100%;
  padding: 8px 15px;

  color: white;
  font-size: 15px;
  line-height: 17px;

  border: none;
  background: $mine-shaft;
  border-radius: 5px;
  box-shadow: 0 0 1px 2px $mine-shaft !important; // we need it because of chrome auto-fill nullifier

  &::placeholder {
    color: $half-white;
  }
}

.text-input__error {
  padding: 3px 0 3px;

  font: $roboto-regular;
  font-size: 12px;
  color: $brick-red;
  text-align: right;

  visibility: hidden;

  &_visible {
    visibility: visible;
  }
}

.text-input__eye {
  position: absolute;
  top: 5px;
  right: 10px;

  height: 25px;
  width: 25px;

  background: url("#{$images_path}icons/visibility_eye.svg") no-repeat center;
  border: none;

  cursor: pointer;

  &_off {
    background: url("#{$images_path}icons/visibility_eye_off.svg") no-repeat center;
    background-size: 19px;
  }
}
