@import "src/styles/variables";

.ReactModal {
  &__Overlay {
    z-index: 10;
  }

  &__Content {
    max-width: 450px;
    width: 100%;
  }
}

.modal-container {
  background: $shark;
  border: 1px solid $faded-white;
  border-radius: 5px;
}

.modal-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  border-bottom: 1px solid $faded-white;

  &__title {
    padding-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;

    color: $white;
    font-size: 1.3rem;
    font-weight: 500;

    cursor: default;
  }

  &__close-button {
    min-width: 20px;
    width: 20px;
    height: 20px;

    background: url("#{$images_path}icons/close_ico.svg") no-repeat center;

    cursor: pointer;
  }
}

.folders-list-modal {
  max-height: 400px;
  overflow: auto;
}

.modal-container-content {
  padding: 15px;

  color: $white;
}
