@import "src/styles/variables";

.burger {
  display: none;
  cursor: pointer;
}

.burger-line {
  width: 30px;
  height: 3px;
  background: white;
}

.history {
  .burger {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .burger {
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: space-around;
  }
}
