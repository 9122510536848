@import "src/styles/variables";

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(auto-fill, 230px);
  grid-gap: 10px;
  flex-grow: 1;
  justify-content: center;
  overflow: auto;
}

.empty-folder-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 20px;

  text-align: center;

  &__title {
    margin-bottom: 20px;

    color: $white;
    font-size: 1.3rem;

    cursor: default;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;

    color: $half-white;
    font-size: 1.2rem;

    cursor: default;
  }

  &__upload {
    input {
      padding: 0;

      font-size: 1.2rem;
    }
  }
}

@media (max-width: 320px) {
  .content-grid {
    grid-template-columns: repeat(auto-fill, 120px);
    grid-template-rows: repeat(auto-fill, 230px);
  }
}

@media (max-width: 375px) {
  .content-grid {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-template-rows: repeat(auto-fill, 230px);
  }
}

@media (max-width: 425px) {
  .content-grid {
    grid-template-columns: repeat(auto-fill, 170px);
    grid-template-rows: repeat(auto-fill, 230px);
  }
}

@media (max-width:816px) {
  .empty-folder-content__info {
    flex-direction: column;
  }
}
