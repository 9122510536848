@import "src/styles/variables";

.upload-progress {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__element {
    display: block;
    height: 40px;
    width: 100%;
  }

  &__link {
    display: flex;
    width: 30px;

    input {
      background: url("#{$images_path}icons/share_ico.svg") no-repeat center/25px;
    }
  }

  .progress-bar {
    margin-right: 10px;
  }
}

.progress-bar {
  flex-grow: 1;

  div {
    overflow: hidden;

    div {
      background: linear-gradient(180deg, #65B3FF 0%, #2D97FF 100%);
    }
  }
}

.progress-bar-overlay {
  position: static;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
