@import "./src/styles/variables";

.page-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: page-sidebar;
  max-height: calc(100vh - 100px);
  height: 100%;
  padding: 0 10px;

  border-right: 1px solid $mine-shaft-light;
}

.page-sidebar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;

  &__title {
    padding-left: 10px;

    color: $white;
    font-size: 1.3rem;

    cursor: default;
  }

  &__back-button {
    position: relative;
    display: block;

    .button__element {
      padding-left: 15px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      width: 14px;
      height: 12px;
      background: url("#{$images_path}icons/arrow_down_sign_to_navigate.svg") no-repeat left center / 14px;
      transform: rotate(90deg);
    }
  }
}

.page-sidebar-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.page-sidebar-setitngs-item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    .page-sidebar-setitngs-item__button {
      color: $faded-white;
    }
  }

  &__title {
    color: $white;
    font-size: 1.2rem;
    font-weight: 500;

    cursor: default;
  }

  &__button {
    color: $almost-white;
    font-size: 0.8rem;

    cursor: pointer;
  }
}

.page-sidebar-bottom {
  width: 100%;
  padding: 10px;

  &__item {
    display: block;
    width: 100%;
    padding: 16px 10px 15px 40px;
    color: $almost-white;
    text-decoration: none;
    font-size: 1.15rem;

    cursor: pointer;

    &:hover {
      color: $white;
    }
  }

  &__history {
    background: url("#{$images_path}icons/history_ico.svg") no-repeat left center / 25px;
  }

  &__settings {
    text-align: left;

    background: url("#{$images_path}icons/settings_ico.svg") no-repeat left center / 25px;
  }
}

.page-sidebar-folders {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

.page-sidebar-underlay {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: none;
}

.history {
  .page-sidebar {
    display: none;
  }

  .page-sidebar-bottom__history {
    display: none;
  }
}

@media (max-width: 768px) {
  .show {
    .page-sidebar {
      display: flex;
      transform: translateX(0);
    }

    .page-sidebar-underlay {
      display: block;
    }
  }

  .history {
    .logo {
      display: none;
    }

    .page-sidebar {
      display: flex;
    }
  }

  .page-sidebar {
    z-index: 2;
    top: 100px;
    position: absolute;
    width: 300px;

    background: $shark;

    transform: translateX(-350px);
    transition: transform 0.3s;
  }
}
