@import "./src/styles/variables";

.auth-page {
  display: grid;
  height: 100vh;
  width: 100%;
  overflow: auto;

  grid-template-columns: minmax(200px, 330px);
  justify-content: center;
  justify-items: center;
  align-content: center;

  background: $shark;

  .logo {
    width: 55px;
    margin-bottom: 50px;
  }
}

.auth-page__title {
  margin-bottom: 6px;

  color: $white;
  font: $roboto-regular;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-decoration: none;

  cursor: default;
}

.auth-page-form {
  width: 100%;
}

.auth-page-form__error {
  margin-bottom: 10px;

  color: $brick-red;

  visibility: collapse;

  &_show {
    visibility: visible;
  }
}

.auth-page-form__reset-link {
  display: block;
  width: fit-content;
  margin: 0 0 20px auto;

  text-align: right;
}

.auth-page-form__send-button {
  margin-bottom: 20px;
}

.auth-page-suggestion {
  margin-bottom: 20px;

  font: $roboto-regular;

  &_small {
    text-align: center;
  }

  span {
    color: $mid-gray;
  }
}
