@import "src/styles/variables";

.page-header-file-info {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;

  &__wrapper {
    display: grid;
    grid-template-columns: 50px 1fr;
    gap: 10px;
    align-content: center;
    justify-items: start;
  }

  &__details {
    display: grid;
    grid-template-columns: 2fr 1.5fr 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  &__actions {
    display: flex;
    //grid-template-columns: repeat(auto-fit, minmax(10px, 75px));
    justify-content: flex-end;
    align-items: center;

    .button {
      &:last-child {
        margin: 0;
      }

      input {
        padding: 10px 20px;

        color: $almost-white;
      }

      &.sign-in {
        margin-right: 10px;
      }

      &.sign-up {
        input {
          padding: 10px 30px;
        }
      }
    }

    &.placeholder {
      width: 100%;
      height: 100%;

      background: url("#{$images_path}placeholders/header-placeholder-right.svg") no-repeat right center/300px;
    }
  }

  &__username,
  &__date,
  &__views {
    padding-left: 20px;
    overflow: hidden;

    color: $half-white;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
  }

  &__username {
    background: url("#{$images_path}icons/user_ico.svg") no-repeat left/15px;
  }

  &__date {
    background: url("#{$images_path}icons/calendar_ico.svg") no-repeat left/15px;
  }

  &__views {
    background: url("#{$images_path}icons/views_ico.svg") no-repeat left/15px;
  }

  &__info {
    display: grid;
    grid-template-rows: 22px 22px;
    max-width: 300px;
    margin-right: 10px;

    &.placeholder {
      display: block;
      width: 100%;
      height: 100%;

      background: url("#{$images_path}placeholders/header-placeholder-left.svg") no-repeat 10px;
    }
  }

  &__rename-button {
    overflow: hidden;

    text-overflow: ellipsis;

    input {
      padding: 0;
      overflow: hidden;

      color: $almost-white;
      text-align: left;
      text-overflow: ellipsis;
    }
  }

  &__file-info {
    overflow: hidden;
    cursor: default;

    color: $almost-white;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__unregistered {
    display: flex;
    grid-row: span 2;
    align-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__home-button {
    justify-self: center;
    min-width: 35px;

    background: url("#{$images_path}icons/home_ico.svg") no-repeat center;

    input {
      height: 100%;
    }

    &.unregistered {
      min-width: 50px;

      background: url("#{$images_path}logo.svg") no-repeat center;
    }
  }
}

@media (max-width: 425px) {
  .page-header-info__file-info {
    display: none;

    &.mobile {
      display: flex;
    }
  }
}

@media (max-width: 768px) {
  .page-header-file-info {
    &__download-button,
    &__resnap-button {
      display: none;
    }

    &__link-button {
      &.button {
        max-width: 25px;
        margin-right: 10px;

        background: url("#{$images_path}icons/share_ico.svg") no-repeat center/25px;

        input {
          color: transparent;
        }
      }
    }
  }
}
