@import "src/styles/variables";

.content-element {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;

  &:hover {
    background-color: $strong-faded-white;

    .link-container__link {
      display: block;
    }

    .checkbox-container {
      display: flex;

      background: url("#{$images_path}icons/checkbox_empty_ico.svg") no-repeat center center/22px 22px;
    }

    .content-element__description {
      color: $almost-white;
    }
  }

  &.selected {
    .checkbox-container {
      display: flex;

      background: url("#{$images_path}icons/checkbox_checked_ico.svg") no-repeat center center/22px 22px;
    }
  }

  &__description {
    padding: 10px 5px;
    max-width: 160px;
    max-height: 30px;
    overflow: hidden;

    color: $half-white;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }
}

.content-element-image_preview {
  position: relative;
  height: 100%;
  width: 100%;

  &__title {
    left: calc(50% - 60px);
    top: calc(50% - 30px);
    position: absolute;
    padding: 20px;

    color: $white;
    font-size: 1.3rem;
    text-shadow: $black 0 0 10px;

    background: $strong-faded-white;
    border-radius: 5px;
    cursor: default;
  }
}

.content-element-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__element {
    max-width: calc(100% - 20px);
    max-height: 150px;

    &.blured {
      filter: blur(8px);
    }
  }

  &__element-placeholder {
    color: $white;
  }
}

.content-element-placeholder {
  height: auto;
  width: 100%;
}

.checkbox-container {
  left: 10px;
  top: 10px;
  position: absolute;
  display: none;
  width: 20px;
  height: 20px;

  background: url("#{$images_path}icons/checkbox_empty_ico.svg") no-repeat center center/22px 22px;
  border-radius: 50%;

  cursor: pointer;
}

.link-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__link {
    display: none;
    padding: 5px 25px;

    font: $roboto-regular;
    font-size: 13px;
    font-weight: 500;
    color: $black;
    text-transform: capitalize;
    text-decoration: none;

    background: white;
    border-radius: 3px;
  }
}
