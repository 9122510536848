@import "~normalize.css/normalize.css";
@import "variables";

* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding-left: 0;

  list-style: none;
}

button {
  padding: 0;

  background: transparent;
  border: none;
  border-radius: 0;
}

textarea {
  resize:none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: $white;
}
