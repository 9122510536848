@import "src/styles/variables";

.new-folder-form,
.change-nickname-form,
.change-name-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  &__send {
    min-width: 165px;
  }

  &__error {
    min-height: 22px;
    padding-bottom: 5px;
    visibility: hidden;
    cursor: default;

    color: $brick-red;

    &.visible {
      visibility: visible;
    }
  }
}
