@import "./src/styles/variables";

.button {
  &__element {
    width: 100%;
    padding: 10px;

    border: none;
    border-radius: 5px;

    color: $white;
    font: $roboto-regular;
    cursor: pointer;

    &[disabled] {
      opacity: 0.42;
    }

    &_blue {
      background: linear-gradient(180deg, $dodger-blue 0%, $malibu 100%);
    }

    &_yellow {
      background: $eucalyptus;
    }

    &_gray {
      background: $mine-shaft;
    }

    &_red {
      background-color: $brick-red;
    }

    &_transparent {
      color: $malibu;

      background: none;
    }

    &_list {
      padding: 8px 15px;

      background: transparent;
      border-radius: 0;

      &:hover {
        background: $faded-white;
        border-radius: 5px;
      }
    }
  }
}
