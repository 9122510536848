@import "src/styles/variables";

.dropdown-list-item {
  min-width: 85px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.adaptive,
  &.hidden {
    display: none;
  }
}

@media (max-width: 768px) {
  .dropdown-list-item {
    &.adaptive {
      display: block;
    }
  }
}
