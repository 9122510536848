@import "src/styles/variables";

.fine-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $shark;

  &__main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 330px;
    width: 100%;

    img {
      display: block;
      width: 55px;
      margin-bottom: 50px;
    }
  }

  &__title {
    margin-bottom: 5px;

    color: $white;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
  }

  &__info {
    margin-bottom: 50px;

    color: $half-white;
    font-size: 15px;
  }

  &__button {
    width: 100%;
  }
}
