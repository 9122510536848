@import "src/styles/variables";

.page-header {
  display: grid;
  align-items: center;
  grid-area: page-header;
  grid-template-columns: 300px 1fr;

  border-bottom: 1px solid $mine-shaft-light;

  &__title {
    display: block;
    overflow: hidden;

    color: $white;
    font-size: 1.25rem;
    text-overflow: ellipsis;
  }

  .logo {
    width: 50px;
    margin-right: 20px;
  }
}

.page-header-logo {
  display: flex;
  align-content: center;
  padding: 0 20px;

  &.mobile {
    display: none;
  }
}

.page-header-breadcrumbs {
  display: flex;
  align-items: center;

  &:hover {
    .page-header-breadcrumbs__button {
      display: flex;

      input {
        display: block;
      }
    }

    .page-header-breadcrumbs__title {
      display: none;
    }
  }

  &__button,
  &__title {
    color: $almost-white;
    font-size: 1.2rem;
  }

  &__button {
    input {
      display: none;
      padding: 0;

      color: inherit;
      font-size: inherit;
    }
  }

  &__title {
    display: block;
    max-width: 200px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.page-header-info {
  display: grid;
  grid-template-columns: 70fr 20fr 10fr;
  grid-gap: 10px;
  align-items: center;
  padding: 0 20px;

  &.disabled {
    display: none;
  }

  &__file-info {
    font-size: 1.25rem;
    font-weight: 400;
    color: $white;

    cursor: default;

    &.mobile {
      display: none;
    }
  }
}

.page-header-file-actions {
  display: grid;
  grid-template-columns: 1fr repeat(3, 25px);
  grid-gap: 10px;

  &__select-visible {
    max-width: 150px;

    input {
      font-size: 1.25rem;
      font-weight: 400;
      color: $white;
    }
  }

  &__share,
  &__move,
  &__remove {
    display: flex;
  }

  &__share {
    input {
      background: url("#{$images_path}icons/share_ico.svg") no-repeat center center/24px;
    }
  }

  &__move {
    input {
      background: url("#{$images_path}icons/folder_move_ico.svg") no-repeat center center/24px 22px;
    }
  }

  &__remove {
    input {
      background: url("#{$images_path}icons/trash_ico.svg") no-repeat center center/24px 22px;
    }
  }
}

.page-header-content {
  &.disabled {
    display: none;
  }

  &__search-field {
    max-width: 500px;
    margin-right: 20px;

    input {
      padding-left: 40px;

      background: url("#{$images_path}icons/search_ico.svg") no-repeat 15px center/20px, $mine-shaft;
    }
  }

  &__upload-button {
    input {
      padding: 10px 40px;
    }
  }

  &__full-width {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0 20px;

    .burger {
      margin-right: 20px;
    }
  }

  &__mobile {
    display: none;
    grid-template-columns: 1fr 10fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    padding: 20px;

    &.search {
      grid-template-columns: 1fr 10fr 1fr;
    }
  }

  &__search-button {
    min-width: 30px;

    background: url("#{$images_path}icons/search_ico.svg") no-repeat center;
  }

  &__options-button {
    display: block;
    min-height: 25px;
    min-width: 25px;
    padding: 0 20px 10px 20px;

    background: url("#{$images_path}icons/menu_ico.svg") no-repeat center/25px;

    cursor: pointer;
  }

  &__close-button {
    min-width: 30px;

    background: url("#{$images_path}icons/close_ico.svg") no-repeat center/25px;
  }
}

.page-header-content-manage {
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 600px;
}

@media (max-width: 576px) {
  .page-header-content {
    &__full-width {
      display: none;
    }

    &__mobile {
      display: grid;
    }
  }
}

@media (max-width: 425px) {
  .page-header-file-actions {
    grid-template-columns: repeat(3, 25px);

    &__select-visible {
      display: none;
    }
  }

  .history {
    .page-header-file-actions {
      grid-template-columns: repeat(1, 25px);
    }
  }
}

@media (max-width: 768px) {
  .page-header {
    grid-template-columns: auto;
  }

  .page-header-logo {
    display: none;

    &.mobile {
      display: flex;
    }
  }

  .page-header-content {
    justify-content: space-between;

    &__full-width {
      justify-content: space-between;
    }
  }
}
