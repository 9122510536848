@import "src/styles/variables";

.pagination-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
};

.pagination {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 30px));
  justify-content: flex-end;
  grid-gap: 10px;
  width: 100%;

  border-radius: 50%;

  &__trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100%;

    border-radius: 50%;

    color: $half-white;
    font-size: 1.2rem;

    cursor: pointer;
  }

  &__active {
    border-radius: 50%;
    background: $dodger-blue;
  }

  &__previous {
    grid-column: span 2;
    padding-right: 10px;

    text-align: right;

    background: url("#{$images_path}icons/paginator_arrow_ico.svg") no-repeat left/12px;

    a {
      width: 100%;

      font-size: 1.2rem;
    }
  }

  &__next {
    grid-column: span 2;
    padding-right: 10px;

    text-align: left;

    background: url("#{$images_path}icons/paginator_arrow_ico.svg") no-repeat left/12px;

    transform: rotate(180deg);

    a {
      width: 100%;

      font-size: 1.2rem;

      transform: rotate(-180deg);
    }
  }
}

.pagination-info {
  display: block;

  color: $half-white;
  font-size: 1.2rem;

  cursor: default;
}

@media (max-width: 940px) {
  .pagination-info {
    margin-bottom: 10px;

    text-align: center;
  }

  .pagination-wrapper {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .pagination {
    grid-template-columns: repeat(auto-fit, minmax(1px, 28px));
    justify-content: center;

    &__trigger {
      height: 28px;
    }
  }
}

@media (max-width: 425px) {
  .pagination {
    grid-template-columns: repeat(auto-fit, minmax(1px, 25px));
    grid-gap: 5px;
    justify-content: center;

    &__trigger {
      height: 25px;

      font-size: 1rem;
    }

    &__previous,
    &__next {
      grid-column: span 1;

      color: transparent;
    }
  }

  .pagination-info {
    display: none;
  }
}
