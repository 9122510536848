@import "src/styles/variables";

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;

  &__element {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.dropdown-list {
  position: absolute;
  top: 30px;
  z-index: 1;
  display: none;
  padding: 15px 10px;

  background-color: $mine-shaft;
  border: 2px solid $mine-shaft-light;
  border-radius: 5px;

  ul {
    li {
      font-size: 1.3rem;
      color: $almost-white;
    }
  }
}

