@import "src/styles/variables";

.file-page {
  position: relative;
}

.page-content-work-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  overflow: auto;

  &.zoom-in {
    padding: 0;
  }
}

.page-content-wrapper {
  position: relative;
  display: flex;

  max-height: 100%;
  max-width: 100%;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__loading {
    font-size: 1.3rem;
    color: $white;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.zoom-in {
  .image-wrapper {
    img {
      max-height: none;
      max-width: none;

      cursor: zoom-out;
    }
  }
}

.zoom-out {
  .image-wrapper {
    img {
      max-height: 100%;
      max-width: 100%;

      cursor: zoom-in;
    }
  }
}

.view-navigation-button {
  position: absolute;
  top: calc(50% - 25px);

  display: flex;
  width: 55px;
  height: 55px;

  background: url("#{$images_path}icons/arrow_ico.svg") no-repeat center/55px 55px, $mine-shaft-half;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: $mine-shaft;
  }

  button {
    border-radius: 50%;
  }

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;

    transform: rotate(180deg);
  }
}
