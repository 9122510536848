@import "variables";

* {
  font: $roboto-regular;
  scrollbar-color: $scrollbar-front $scrollbar-back;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

*::-webkit-scrollbar-track {
  background-color: $scrollbar-back;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-front;
  border-radius: 10px;
}

*::-webkit-progress-value,
*::-webkit-progress-bar {
  background: linear-gradient(180deg, #65B3FF 0%, #2D97FF 100%);
}


body {
  overflow: hidden;
}

.link {
  color: $dodger-blue;
  text-decoration: none;
}

@media (max-width: 575.98px) {
  *::-webkit-scrollbar {
    width: auto;
    height: auto;
  }
}
