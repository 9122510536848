@import "variables";

@font-face {
  font-family: 'Roboto';
  src: url("#{$fonts_path}Roboto/Roboto-Thin.woff2") format("woff2"),
  url("#{$fonts_path}Roboto/Roboto-Thin.woff") format("woff"),
  url("#{$fonts_path}Roboto/Roboto-Thin.ttf") format("truetype"),
  url("#{$fonts_path}Roboto/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Black.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Black.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Black.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Black.svg#Roboto-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Regular.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Regular.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Regular.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-LightItalic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-LightItalic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-LightItalic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Medium.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Medium.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Medium.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Light.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Light.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Light.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Bold.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Bold.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Bold.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-BoldItalic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-MediumItalic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-MediumItalic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-ThinItalic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-ThinItalic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-ThinItalic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-Italic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-Italic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-Italic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts_path}Roboto/Roboto-BlackItalic.woff2') format('woff2'),
  url('#{$fonts_path}Roboto/Roboto-BlackItalic.woff') format('woff'),
  url('#{$fonts_path}Roboto/Roboto-BlackItalic.ttf') format('truetype'),
  url('#{$fonts_path}Roboto/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
