@import "src/styles/variables";

.remove-confirm-dialog {
  display: flex;
  flex-direction: column;

  &__description {
    margin-bottom: 20px;

    cursor: default;
  }
}

.remove-confirm-dialog-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button {
    margin-right: 20px;

    input {
      padding: 10px 40px;
    }

    &:last-child {
      margin: 0;
    }
  }
}
