@import "./src/styles/variables";

.page {
  display: grid;
  grid-template-areas: "page-header page-header"
                       "page-sidebar page-content";
  grid-template-columns: 300px 1fr;
  grid-template-rows: 100px 1fr;
  height: 100vh;

  background: $shark;

  &.history,
  &.file-page {
    grid-template-areas: "page-header page-header"
                         "page-content page-content";
    grid-template-columns: auto;
  }
}

.page-content {
  display: flex;
  flex-direction: column;
  grid-area: page-content;
  max-height: calc(100vh - 100px);

  &.history,
  &.file-page {
    grid-template-rows: auto;
  }
}

.history {
  .page-header-file-actions {
    grid-template-columns: 1fr repeat(1, 25px);
  }
}

.file-page {
  .page-header {
    grid-template-columns: auto;
  }

  .page-content {
    display: grid;
    overflow: auto;
  }
}

.suspense-block {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100vh;

  color: $mine-shaft;
  font-size: 1.3rem;

  background: url("#{$images_path}page-loading-spiner.svg") no-repeat center, $shark;
}

@media (max-width: 576px) {
  .page-content {
    grid-template-rows: 115px auto;
  }
}

@media (max-width: 768px) {
  .page {
    grid-template-areas: "page-header page-header"
                         "page-content page-content";
  }
}
