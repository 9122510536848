@import "src/styles/variables";

.file-info-modal {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  align-items: center;

  &__title,
  &__info {
    font-size: 1.2rem;

    cursor: default;
  }

  &__title {
    color: $half-white;
    font-weight: 500;
  }

  &__info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: $almost-white;
  }

  &__info-button {
    overflow: hidden;

    border-bottom: 2px solid $almost-white;

    input {
      padding: 0;
      overflow: hidden;

      color: $almost-white;
      font-size: 1.2rem;
      text-overflow: ellipsis;
    }
  }
}
