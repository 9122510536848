@import "./src/styles/variables";

.auth-password-req {
  margin-bottom: 20px;

  &__title {
    margin-bottom: 5px;

    color: $white;
    font: $roboto-regular;
  }

  &__list {
    padding-left: 19px;

    list-style: disc;
    color: $brick-red;
    font: $roboto-regular;
  }

  &__list-item {
    margin-bottom: 5px;

    &_selected {
      color: $eucalyptus;
    }

    &.empty {
      color: $half-white;
    }
  }
}
