@import "./src/styles/variables";

.folder-button {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 12px;

  color: $white;
  text-align: left;

  border-radius: 6px;

  cursor: default;

  &.selected,
  &.pressed {
    background: linear-gradient($malibu, $dodger-blue);

    .folder-button-side__dropdown-wrapper {
      visibility: visible;
    }
  }

  &:hover {
    background: $strong-faded-white;

    .folder-button-side__dropdown-wrapper {
      visibility: visible;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.folder-button-side {
  display: flex;

  &__dropdown-wrapper {
    display: block;
    visibility: hidden;
  }

  &__dropdown-button {
    display: block;
    height: 100%;
    width: 20px;

    background: url("#{$images_path}icons/menu_ico.svg") no-repeat center;

    cursor: pointer;
  }

  &__share-button {
    display: block;
    height: 100%;
    width: 25px;
    margin-right: 15px;

    background: url("#{$images_path}icons/share_ico.svg") no-repeat center/25px;

    cursor: pointer;
  }
}

.folder-button-content {
  display: flex;
  justify-content: space-between;
}

.folder-button-main {
  display: block;
  width: 100%;
  padding: 12px 0 12px 45px;
  overflow: hidden;

  color: $white;
  text-align: left;
  text-overflow: ellipsis;

  background: url("#{$images_path}icons/folder_ico.svg") no-repeat left center;
  cursor: pointer;
}
