@import "src/styles/variables";

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  color: $white;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;

    font: $roboto-regular;
    font-size: 1.25rem;

    cursor: default;
  }
}

.content-header-date-sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__title {
    margin-right: 15px;

    font-size: 1.1rem;

    cursor: default;
  }
}

@media (max-width: 576px) {
  .content-header {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 768px) {
  .content-header__title {
    max-width: 275px;
  }
}

@media (max-width: 1024px) {
  .content-header__title {
    max-width: 175px;
  }
}
