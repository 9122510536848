@import "src/styles/variables";

.dropdown-toggle-button {
  position: relative;

  min-width: 150px;

  background: $mine-shaft;

  border: 1px solid $mine-shaft-light;
  border-radius: 5px;

  cursor: pointer;

  &__element {
    width: 100%;
    padding: 8px 40px 8px 15px;

    color: $white;
    font-size: 1.1rem;
    text-align: left;

    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      right: 10px;

      width: 18px;
      height: 18px;

      background: url("#{$images_path}icons/arrow_down_sign_to_navigate.svg") no-repeat center;
      transition: transform 0.2s;
    }

    &.toggled {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
