@import "src/styles/variables";

.direct {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: auto;

  background-color: $mine-shaft;

  &__element {
    display: block;
    max-width: 100%;
    max-height: 100%;

    cursor: zoom-in;

    &.zoomed {
      max-width: unset;
      max-height: unset;

      cursor: zoom-out;
    }
  }
}
